import React, { useEffect, useRef, useState } from "react";
import WipeBtn from "../../components/atoms/buttons/WipeBtn";
import Fade from "react-reveal/Fade";
import config from "react-reveal/globals";

import { useLanguage } from "../../languageContext";
import useOnScreen from "../../components/atoms/appearance/Appearanc";
import { LightSpeed, Zoom } from "react-reveal";

const AboutHeader = ({
  header,
  para,
  btnText,
  btnLink,
  isAboutUsPage,
  bgImageClass,
  children,
  background,
  currentPage,
  increaseFontSize,
  bgImg,
  bgImgIn,
  bgImgEn,
  bgImgJa,
  bgImgMobile,
  bgImgTablet,
}) => {
  const currentLanguage = useLanguage();
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  useEffect(() => {
    config({ ssrFadeout: isVisible });
  }, [isVisible]);

  return (
    <div ref={ref} className={` section header-outer-section `}>
      <div className={`background-img desktop-wrapper`}>
        {/* <img src={bgImg}></img> */}
        <img
          src={
            currentLanguage === "ja"
              ? bgImgJa
              : currentLanguage === "in"
              ? bgImgIn
              : bgImgEn
          }
          alt="as"
        />
      </div>
      <div className="background-img tablet-wrapper">
        <img src={bgImgTablet} alt="background-header" />
      </div>
      <div className="background-img mobile-wrapper">
        <img src={bgImgMobile} alt="background-header" />
      </div>

      <div
        className={`about-header-wrapper  tablet-bg-${
          currentLanguage ? currentLanguage : "en"
        }`}
      >
        <Fade cascade>
          <div className="header-info-wrapper">
            <h1 className="heading heading-bottom-space">{header}</h1>
            <p className={"para common-bottom-space"}>{para}</p>
            {isAboutUsPage ? (
              ""
            ) : (
              <div className="common-bottom-space header-cta-wrapper">
                <WipeBtn url={btnLink} text={btnText}></WipeBtn>
                <div className="para current-page-text">
                  <span>Home</span> / {currentPage}
                </div>
              </div>
            )}{" "}
          </div>
        </Fade>
      </div>

      {/* <div className={`container header-information-wrapper`}>
        <div
          className={` ${
            currentPage === "lifeatgoken"
              ? "lifeatgoken-header about-header-wrapper"
              : "about-header-wrapper"
          }`}
        >
          <div className="header-info-wrapper">
            <div className="heading">
              <Fade bottom>{header}</Fade>
            </div>
            <div className={"para"}>{para}</div>
            <div className="m-top-20px">
              {isAboutUsPage ? (
                ""
              ) : (
                <WipeBtn url={btnLink} text={btnText}></WipeBtn>
              )}
            </div>
          </div>

          <div className="current-page">
            <span>Home</span> / {currentPage}
          </div>
        </div>
      </div> */}
      {/* <div className="header-mobile-wrapper">
        <div
          className={`about-header-wrapper ${background}-background-${
            currentLanguage ? currentLanguage : "en"
          }`}
        >
          <div className="padding-div"></div>
        </div>
        <div className="header-info-shadow">
          <div
            className={`header-info-wrapper background-${
              currentLanguage ? currentLanguage : "en"
            }`}
          >
            <Fade bottom>
              <div className="heading">{header}</div>
            </Fade>
            <div className={"para"}>{para}</div>
            <div className="m-top-20px">
              {isAboutUsPage ? (
                ""
              ) : (
                <WipeBtn url={btnLink} text={btnText}></WipeBtn>
              )}
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AboutHeader;
