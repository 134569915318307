import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import IndustryCasestudyTabs from "../../molecules/Industry/IndustryCasestudyTabs";
import SlantedHeader from "../../molecules/aboutus/AboutUsHeader";
import IndustrySlider from "../../molecules/Industry/IndustrySlider";
import ContactForm from "../../molecules/ContactForm";

import { useStaticQuery, graphql } from "gatsby";
import IndustryOurClients from "../../molecules/Industry/IndustryOurClients";
//image
import bgImg from "../../images/industryassets/advancetechnology.png";
import ImageBanner from "../../components/atoms/banner/imageBanner";
const AdvancedTechnology = () => {
  const data = useStaticQuery(graphql`
    query GetAdvancedTechnologyData {
      gcms {
        industryPages(where: { industrPageType: ADVANCED_TECHNOLOGY }) {
          industrPageType
          headerParagraph
          solutionsPageSlides {
            listItems
            slideHeading
            paragraph
            slideImage {
              url
            }
            url
          }
          insightPages {
            image {
              url
            }
            slug
            title
            typeOfInsight
            writtenBy
            shortDescription
          }
          linkedInArticlesIframeUrl
        }
      }
    }
  `);

  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO title="Advanced Technology"></SEO>
      {data.gcms && (
        <>
          <SlantedHeader
            header="Advanced Technology"
            para={data.gcms.industryPages[0].headerParagraph}
            btnText="Get In Touch"
            btnLink="/contactus/"
            background={"advancetech"}
            isColorPrimary={true}
            bgImageClass="bg-image-advancedTechnology"
            currentPage="industry advanceTech"
            bgImg={bgImg}
            children={
              <IndustryOurClients
                isAdvancedTechnology={true}
              ></IndustryOurClients>
            }
          ></SlantedHeader>
          {/* <IndustryOurClients isAdvancedTechnology={true}></IndustryOurClients> */}
          <IndustryCasestudyTabs
            heading={"OUR WORK IN THE ADVANCED TECHNOLOGY INDUSTRY"}
            insights={data.gcms.industryPages[0].insightPages}
            linkedinArticlesUrl={
              data.gcms.industryPages[0].linkedInArticlesIframeUrl
            }
          ></IndustryCasestudyTabs>
          <IndustrySlider
            hideUrl={false}
            SliderItems={data.gcms.industryPages[0].solutionsPageSlides}
            heading="Advanced Technology"
          ></IndustrySlider>
        </>
      )}
      {/* <ContactForm showForm={true} typeOfForm="General Inquiry"></ContactForm> */}
      <ImageBanner
        heading="Learn more about our services in the Advanced Technology industry"
        btnText="Get in Touch"
        link="/contactus/"
      ></ImageBanner>
    </Layout>
  );
};

export default AdvancedTechnology;
